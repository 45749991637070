export default {
  required(msg) {
    //key is required
    return (v) => !!v || msg;
  },
  maxLength(msg, max) {
    //'key must be less than 10 characters'
    return (v) => (v && v.length <= max) || msg;
  },
  email(msg) {
    //'E-mail must be valid';
    return (v) => /.+@.+\..+/.test(v) || msg;
  },
  // email(msg) {
  //   return (v) =>
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ||
  //     msg;
  // },
  numeric(msg) {
    //숫자만 가능
    return (v) => /^[0-9]+$/.test(v) || msg;
  },
  powerLimit(msg) {
    //충전용량은 350kwh 이하만 등록 가능
    return (v) => v <= 350 || msg;
  },
  sid(msg) {
    //충전소ID는 영문 또는 숫자만 가능, 최대 10글자
    return (v) => /^[A-Za-z0-9+]{0,10}$/.test(v) || msg;
  },
  cid(msg) {
    return (v) => /^[A-Za-z0-9+]{0,16}$/.test(v) || msg;
  },
  tel(msg) {
    //연락처는 01X-XXXX-XXXX, XX-XXXX-XXXX, XXXX-XXXX
    return (v) =>
      /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(v) ||
      /^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/.test(v) ||
      /^([0-9]{4})-?([0-9]{4})$/.test(v) ||
      msg;
  },
  lat(msg) {
    //위도, 경도는 소수점 포함 실수
    return (v) => /^\d{2}[.]\d{1,6}$/.test(v) || msg;
  },
  lng(msg) {
    return (v) => /^[-]?\d{1,3}[.]\d{1,6}$/.test(v) || msg;
  },
  password(msg) {
    return (v) =>
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/.test(
        v
      ) || msg;
  },
  phone(msg) {
    return (v) =>
      /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(v) || msg;
  },
  loginId(msg) {
    return (v) => /^[a-zA-Z0-9]*$/.test(v) || msg;
  },
  firmwareVersion(msg, lastestVersion) {
    return (v) => v > lastestVersion || msg;
  },
};
