<template>
  <v-app>
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <v-card>
          <v-card-title>Message Monitor</v-card-title>
          <v-card-subtitle>
            {{ "ocpp1.6" == uri.version ? "OCPP Central System" : "" }}
            {{
              "ocpp2.0.1" == uri.version
                ? "OCPP Charging Station Management System"
                : ""
            }}
          </v-card-subtitle>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-row no-gutters>
                    <v-text-field
                      v-model="uri.host"
                      :rules="rules.host"
                      label="OCPP Server URI"
                      outlined
                      clearable
                      :disabled="status.connected"
                    ></v-text-field>
                  </v-row>
                  <v-row no-gutters>
                    <v-select
                      v-model="uri.version"
                      :items="status.versions"
                      label="Versions"
                      outlined
                      :disabled="status.connected"
                    ></v-select>
                  </v-row>
                  <v-row no-gutters>
                    <v-text-field
                      v-model="uri.group"
                      :rules="rules.group"
                      label="Group"
                      placeholder="Group"
                      outlined
                      clearable
                      :disabled="status.connected"
                    ></v-text-field>
                  </v-row>

                  <v-row no-gutters>
                    <v-text-field
                      v-model="uri.token"
                      label="Bearer Token"
                      placeholder="Bearer Token"
                      outlined
                      clearable
                      :disabled="status.connected"
                    ></v-text-field>
                  </v-row>

                  <v-row no-gutters>
                    <v-btn
                      block
                      x-large
                      :color="status.color"
                      @click="statusClick"
                    >
                      {{ status.button }}
                    </v-btn>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="8" :hidden="!status.connected">
                  <v-simple-table dense :hidden="'Connect' == status.button">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Target</th>
                          <th class="text-left">Operation</th>
                          <th class="text-left">Message</th>
                          <th class="text-left">Error</th>
                          <th class="text-left">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in receiveMessages" :key="item.name">
                          <td>{{ item.type }}{{ item.id }}</td>
                          <td>{{ item.operation }}</td>
                          <td>{{ item.message }}</td>
                          <td>{{ item.error }}</td>

                          <td>
                            <OCPPMessage
                              v-if="
                                ('SEND' == item.operation &&
                                  item.message.startsWith('[2')) ||
                                ('CONNECT' == item.operation &&
                                  item.destination != socket.headers.path)
                              "
                              :socket="socket"
                              :item="item"
                              @input="onSendMessage"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>

        <v-dialog v-model="alert.dialog" max-width="400">
          <v-alert border="top" colored-border type="warning" elevation="2">
            {{ alert.message }}
          </v-alert>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import OCPPMessage from "./OCPPMessage";
import Validator from "@/app/app.validator.js";
const Stomp = require("stompjs");

export default {
  components: {
    OCPPMessage,
  },
  data: () => ({
    alert: {
      dialog: false,
      message: null,
    },
    status: {
      connected: false,
      button: "Connect",
      color: "primary",
      versions: ["ocpp1.6", "ocpp2.0.1"],
      type: "", //group or client
    },
    rules: {
      host: [Validator.required("required")],
      group: [Validator.required("required")],
      client: [Validator.required("required")],
    },

    uri: {
      host: "",
      version: "ocpp2.0.1",
      group: "",
      client: "test",
      token: "",
    },

    socket: {
      url: null,
      headers: {
        Authorization: null,
        protocols: null,
        path: null,
      },
      session: null,
    },
    receiveMessages: [],

    receiveMessage: null,

    sendMessage: {
      payloads: [],
      payload: null,
      text: null,
    },
  }),

  methods: {
    statusClick() {
      if ("Connect" == this.status.button) {
        this.socket.headers.protocols = this.uri.version;
        this.socket.headers.path =
          "/" + [this.uri.version, this.uri.group, "*"].join("/");
        this.socket.headers.Authorization = "Bearer " + this.uri.token;

        this.socket.session = Stomp.client(
          this.uri.host + "/connect/websocket"
        );
        this.socket.session.connect(
          this.socket.headers,
          this.onConnected,
          this.onError
        );
      } else {
        this.socket.session.disconnect();
        this.onError("disconnect");
      }
    },

    onConnected(e) {
      console.log("onConnected", e);
      this.status.connected = true;
      this.status.button = "Disconnect";
      this.status.color = "error";
      this.alert.message = "";
      this.alert.dialog = false;

      this.receiveMessages = [];

      const uri = "/topic" + this.socket.headers.path;
      this.socket.session.subscribe(
        uri,
        this.onReceiveMessage,
        this.socket.headers
      );
    },

    onError(e) {
      console.log("onError", e);
      this.status.connected = false;
      this.status.button = "Connect";
      this.status.color = "primary";
      this.alert.message = e;
      this.alert.dialog = true;
    },

    onReceiveMessage(e) {
      console.log("onReceiveMessage", e.headers);
      console.log("onReceiveMessage", e.body);
      // var body = JSON.parse(e.body);
      // console.log("onReceiveMessage", body);

      const msg = {
        id: e.headers.ocppTarget,
        operation: e.headers.ocppOperation,
        error: e.headers.ocppError,
        message: e.body,
      };
      // try{
      //   var x = JSON.parse(msg.message)
      //   console.log("onReceiveMessage1", x);
      //   console.log("onReceiveMessage2", x[1]);
      //   msg.savedId = x[1];
      // }catch(tx){
      //   console.log("onReceiveMessage3", tx);
      // }

      // // msg.destination = e.headers["destination"].replace("/topic", "");
      this.receiveMessages.push(msg);
    },

    onSendMessage(e) {
      const uri = "/app" + e.item.id; //.replace("/topic", );

      let msg = null;
      try {
        msg = JSON.parse(e.text);
        console.log("onSendMessage2", msg);
      } catch (tx) {
        this.alert.message = "" + tx;
        this.alert.dialog = true;
        console.log("onSendMessage2err", tx);
        return;
      }
      this.socket.session.send(uri, {}, e.text);
    },
  },

  mounted() {
    this.uri.host = this.$route.query.host;
    this.uri.group = this.$route.query.group;
    this.uri.token = this.$route.query.token;
  },
};
</script>

<style></style>
