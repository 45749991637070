<template>
  <v-dialog transition="dialog-bottom-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        v-bind="attrs"
        v-on="on"
        color="primary"
        @click="onSendMessageReady()"
      >
        Send Message
      </v-btn>
    </template>
    <template v-slot:default="xyz">
      <v-card>
        <v-toolbar color="primary" dark
          >Write To: {{ item ? item.id : socket.url }}</v-toolbar
        >

        <v-card-text>
          <v-row no-gutters class="my-2"> </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-select
                class="my-0 py-0"
                v-model="sendMessage.payload"
                :items="sendMessage.payloads"
                @change="onSendMessageBefore"
                label="OCPP Messages"
                outlined
                filled
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-textarea
                name="input-7-4"
                :label="'Call or CallResult or CallError'"
                v-model="sendMessage.text"
                filled
                no-resize
                rows="5"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            @click="
              onSendMessage();
              xyz.value = false;
            "
          >
            Write
          </v-btn>
          <v-btn text @click="xyz.value = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: ["socket", "item"],

  data: () => ({
    sendMessage: {
      payloads: [],
      payload: null,
      text: null,
    },
  }),

  methods: {
    onSendMessageReady() {
      this.sendMessage.payloads = Object.keys(
        this.$ocpp[this.socket.headers.protocols]
      );
      this.sendMessage.payload = null;
      this.sendMessage.text = null;
    },

    onSendMessageBefore(item) {
      console.log("onSendMessageBefore", item);
      // alert(item.savedId);
      // generate : (version, action, id)=>{
      const message = this.$ocpp.generate(
        this.socket.headers.protocols,
        this.sendMessage.payload,
        item.savedId
      );
      console.log("onSendMessageBefore", message);
      this.sendMessage.text = JSON.stringify(message);
    },

    onSendMessage() {
      this.$emit("input", { text: this.sendMessage.text, item: this.item });
    },
  },

  mounted() {
    // console.log(this.socket);
  },
};
</script>

<style></style>
